// ThemeManager manages included stylesheet in the HTML, to match the required theme. It requires that the page has a
// stylesheet with id=managed_stylesheet
class ThemeManager {
    theme = "light";
    cssBaseFolder = "/";
    themeDomain = ""; // The domain of the theme, e.g. player_ or app_

    constructor(cssBaseFolder, themeDomain, useSystemTheme = false) {
        this.cssBaseFolder = cssBaseFolder;
        if (themeDomain) {
            this.themeDomain = themeDomain;
        }
        let theme = "light"; //defaults to light

        // A cookie is used to override OS theme settings
        const currentTheme = this.getCookie("theme");
        if (currentTheme !== "") {
            if (currentTheme === "dark") {
                theme = "dark";
            }
        } else if (useSystemTheme && window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            //OS theme setting detected as dark
            theme = "dark";
        }

        //dark theme preferred
        if (theme === "dark") {
            this.setTheme("dark");
        }

        if (useSystemTheme) {
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
                if (event.matches) {
                    this.setTheme("dark");
                } else {
                    this.setTheme("light");
                }
            });
        }
    }

    setTheme(theme) {
        if (theme !== this.theme) {
            let currentStyleSheet = document.getElementById('managed_stylesheet');
            if (!currentStyleSheet) {
                console.error('managed_stylesheet id is not assigned to a stylesheet. Theme has not been changed');
            } else {
                let item = document.createElement("link");
                item.setAttribute("rel", "stylesheet");
                item.setAttribute("href", this.cssBaseFolder + "bs-" + this.themeDomain + theme + ".min.css");
                item.setAttribute("id", "managed_stylesheet");
                let headElement = document.getElementsByTagName('head')[0];
                headElement.removeChild(currentStyleSheet);
                headElement.appendChild(item);
                this.theme = theme;
                this.setCookie("theme", theme, 365);
            }
        }
    }


    setCookie(name, value, days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; SameSite=Lax; path=/";
    }

    getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

}